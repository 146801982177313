import ClipLoader from "react-spinners/ClipLoader";

function Loading() {
  return (
    <ClipLoader
        color="#333"
        loading="true"
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
  );
}

export default Loading;