function Image()
{
    return (
        <div class="about-image-warp">
            <div class="container">
                <div class="about-image">
                    <img src="assets/img/hexagon-3420935_1920.jpg" class="about1" alt="about1" />
                </div>
            </div>
        </div>
    );
}

export default Image;